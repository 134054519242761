<template>
	<div style="background: #FFFFFF;">
		<div class="warningbox">
			<i class="el-icon-warning" style="color: #FF0000;"></i>
			您的店铺已到期，如需恢复正常营业，请及时续费。
		</div>
		<div class="contentbox">
			<div class="leftbox">
				<div class="banner">
					<el-carousel ref="banners" trigger="click" height="510px" indicator-position="none" arrow="never" @change="autochange">
					  <el-carousel-item v-for="(item,index) in bannerlist" :key="index">
							<el-image style="width: 100%; height: 510px" :src="item.image" fit="cover"></el-image>
					  </el-carousel-item>
					</el-carousel>
					<div class="indicatorbox">
						<div class="indicator" @click="changebanner(index)" :class="{'active':index==banner_index}" v-for="(item,index) in bannerlist" :key="index">
							<div class="title">{{item.name}}</div>
							<div class="text">{{item.desc}}</div>
						</div>
					</div>
				</div>
			
				<div class="advbox">
					<div class="advtitle">码扣SAAS云提供全面的店铺经营方案</div>
					<div class="numberbox">
						<div class="box">
							<div class="title"><span>2</span>万单</div>
							<div class="text">
								云服务免费额度
								<i class="el-icon-arrow-right"></i>
							</div>
						</div>
						<div class="box">
							<div class="title"><span>4</span>个</div>
							<div class="text">
								销售推广渠道
								<i class="el-icon-arrow-right"></i>
							</div>
						</div>
						<div class="box">
							<div class="title"><span>15</span>个</div>
							<div class="text">
								营销推广渠道
								<i class="el-icon-arrow-right"></i>
							</div>
						</div>
						<div class="box">
							<div class="title"><span>4</span>个</div>
							<div class="text">
								线下收银工具
								<i class="el-icon-arrow-right"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="apptitle">购买{{userinfo.shop.application.app_name}}({{year}})版本</div>
				<div class="appbox" v-for="(item,index) in apps" :key="index">
					<div class="left">
						<el-image class="icon" :src="item.icon"></el-image>
						<div class="name">{{item.name}}</div>
					</div>
					<div class="right">
						<div class="online" v-if="item.status==1">已上线</div>
						<div class="offline" v-if="item.status==0">即将上线</div>
					</div>
				</div>
				<div class="paybox">
					<div class="box" :class="{'active':paytype==1}" @click="paytype=1">一年</div>
					<div class="box" :class="{'active':paytype==2}" @click="paytype=2">三年</div>
					<div class="box" :class="{'active':paytype==3}" @click="paytype=3">五年</div>
				</div>
				
				<div class="pircebox">
					<div class="pirce">
						<div class="text">合计</div>
						<div class="money"><span>￥</span>{{computers()}}</div>
					</div>
				</div>
				
				<div class="paybtn" @click='pay'>立即支付</div>
			</div>
		</div>
	
	    <el-dialog title="二维码" :visible.sync="payVisible" width="300px">
	    	<div style="text-align: center;" v-loading='loading'>
	    		<el-image style="width: 200px; height: 200px;" :src="url"></el-image>
	    	</div>
	    	<span slot="footer" class="dialog-footer" style="text-align: center;">
	    		<el-button @click="payComplete">支付完成</el-button>
	    	</span>
	    </el-dialog>
	
	</div>
	
</template>

<script>
import { eltips } from '@/util/util.js';
import QR from "@/components/common/wxqrcode.js";
import {resetRouter} from "@/router/index.js";
export default {
	name: 'payapps',
	data() {
		return {
			bannerlist:[
				{
					image:'https://img01.yzcdn.cn/upload_files/2020/12/22/FvAm9SppSj9ODMOwrfVejFan_Lux.png',
					desc:'线上和门店全场景覆盖',
					name:'零售场景化',
				},
				{
					image:'https://img01.yzcdn.cn/upload_files/2020/12/22/FvAm9SppSj9ODMOwrfVejFan_Lux.png',
					desc:'线上和门店全场景覆盖',
					name:'零售场景化',
				},
				{
					image:'https://img01.yzcdn.cn/upload_files/2020/12/22/FvAm9SppSj9ODMOwrfVejFan_Lux.png',
					desc:'线上和门店全场景覆盖',
					name:'零售场景化',
				},
			],
			apps:[
				{name:'微信小程序',icon:require('@/assets/icon/icon-wechat-mnp.png'),status:1},
				{name:'微信公众号',icon:require('@/assets/icon/icon-wechat-h5.png'),status:0},
				{name:'H5网页端',icon:require('@/assets/icon/icon-h5.png'),status:0},
				{name:'支付宝小程序',icon:require('@/assets/icon/icon-zhi-mnp.png'),status:0},
				{name:'百度小程序',icon:require('@/assets/icon/icon-baidu-mnp.png'),status:0},
				{name:'抖音小程序',icon:require('@/assets/icon/icon-dy-mnp.png'),status:0},
				{name:'头条小程序',icon:require('@/assets/icon/icon-toutiao-mnp.png'),status:0},
			],
			paytype:1,
			banner_index:0,
			year:new Date().getFullYear(),
			url: '',
			withdrawalVisible: false,
			loading:false,
			payVisible: false,
			userinfo:''
		};
	},
	created() {
		this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
	},
	methods:{
		autochange(e){
			this.banner_index = e
		},
		changebanner(index){
			this.banner_index = index
			this.$refs.banners.setActiveItem(index)
		},
		pay(){
			let data ={
				yesrs:this.paytype
			}
			let url = '/api/get/renewals/qr'
			this.payVisible=true
			this.loading=true
			this.axios.get(url, data).then(res => {
				if (res.code == 200) {
					let code_url = QR.createQrCodeImg(res.data.qr_code, {
						size: parseInt(300),
					});
					
					this.url=code_url
					this.loading=false
				} else {
					eltips(res.msg, 'error')
				}
			}).catch(err => {
				console.log(err)
			})
		},
		computers(){
			let price=JSON.parse(sessionStorage.getItem("userinfo")).shop.application.retail_price;
			let num=1
			if(this.paytype==2) num=3
			if(this.paytype==3) num=5
			return price*num
		},
		payComplete() {
			this.payVisible = false
			let userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
			let type = userinfo.type;
			let guard_name = userinfo.guard_name;
			let guard_info = {
				type: type,
			};
			guard_info.name = '';
			guard_info.guard_name = "platform_agent_admin_user";
			guard_info.shop_id = '';
			this.$router.replace({ path: "/agentlogin" }) 
			resetRouter();
			this.$store.commit("routestatus", 0);
			sessionStorage.setItem("guard_info", JSON.stringify(guard_info));
			sessionStorage.removeItem("routestext");
			sessionStorage.removeItem("routes");
			sessionStorage.removeItem("userinfo");
			sessionStorage.removeItem("token");
			sessionStorage.removeItem("pluginroute");
			sessionStorage.removeItem("pluginid");
			sessionStorage.removeItem("isApply");
			
		},
	}
};
</script>
<style scoped lang="less">
.warningbox{
	background: #ffebeb;
	padding:0 10px;
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 60px;
	font-weight: bold;
}	

.dialog-footer{
	display: flex;
	align-items: center;
	justify-content: center;
}

.contentbox{
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	padding-left: 10px;
	.leftbox{
		flex: 1;
		.banner{
			width: 100%;
			height: 510px;
			position: relative;
			.indicatorbox{
				width: 100%;
				display: flex;
				justify-content: center;
				position: absolute;
				left: 0;
				bottom: 10px;
				z-index: 999;
				.indicator{
					width: 230px;
					height: 70px;
					margin-right: 10px;
					color: #888;
					background: #FFFFFF;
					border-radius: 2px;
					padding: 12px;
					cursor: pointer;
					.title{
						font-size: 16px;
						line-height: 22px;
						font-weight: 600;
						margin-bottom: 4px;
					}
					.text{
						font-size: 12px;
						line-height: 17px;
					}
					&:last-child{
						margin-right: 0;
					}
				}
				.active{
					background: rgb(246,219,161);
					.title,.text{
						color: rgb(157, 107, 68);
					}
				}
			}
		}
		.advbox{
			width: 750px;
			margin: 30px auto 0;
			.advtitle{
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 20px;
			}
			.numberbox{
				display: flex;
				justify-content: space-between;
				color: rgb(161, 101, 54);
				.box{
					.title{
						font-size: 16px;
						line-height: 32px;
						cursor: pointer;
						font-weight: 500;
						span {
						    font-size: 42px;
						    line-height: 50px;
						    vertical-align: bottom;
						    padding-right: 2px;
						    font-family: Avenir;
						    font-weight: 800;
						    margin-bottom: -2px;
						    display: inline-block;
						    font-style: italic;
						}
					}
					
					.text{
						line-height: 20px;
						margin-top: 4px;
						color:#646566;
					}
				}
			}
		}
	}
	.rightbox{
		flex: 0 0 300px;	
		margin-left: 20px;
		// min-height: calc(100vh - 110px);
		padding:0 30px 30px;
		.apptitle{
			font-size: 20px;
			color: #323233;
			font-weight: 500;
			margin-bottom: 10px;
		}
		.appbox{
			height: 60px;
			border: 1px solid #c2c2c2;
			border-radius: 4px;
			margin-bottom: 8px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 10px;
			.left{
				display: flex;
				align-items: center;
				.icon{
					width: 32px;
					height: 32px;
				}
				.name{
					margin-left: 6px;
					font-size: 16px;
					font-weight: bold;
				}
			}
			.right{
				font-size: 14px;
				.online{
					color: #67C23A;
				}
				.offline{
					color: #999999;
				}
			}
		}
		.paybox{
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			.box{
				border-radius: 4px;
				width: 72px;
				line-height: 50px;
				text-align: center;
				border: 1px solid #c2c2c2;
				cursor: pointer;
			}
			.active{
				background:#114db4;
				color: #FFFFFF;
				border-color: #114db4;
				font-size: 16px;
			}
		}
		.pircebox{
			.pirce{
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				margin-top: 20px;
				.text{
					font-size: 18px;
				}
				.money{
					font-size: 24px;
					color: #FF0000;
					span{
						font-size: 16px;
					}
				}
			}
		}
		
		.paybtn{
			margin-top: 20px;
			line-height: 54px;
			text-align: center;
			background: #114db4;
			color: #FFFFFF;
			font-size: 16px;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
</style>
